<template>
  <section id="mobile-apps" class="service-row page-row page-row--default page-row--small-margin page-row--bg--red">
    <div class="container container--inherit-height">
      <div class="col col--12-12 row-inner-container">
        <div class="col col--7-12">
          <div class="content-image col__layer">
            <div class="iphone-animated">
              <BaseImage :max="2" src="iphone.png" class="iphone-image" alt="iPhone"></BaseImage>
              <MaskEffectImage
                class="wrench-image"
                image-below-src="./img/wrench-below.png"
                image-above-src="./img/wrench-above.png"
                image-fallback-src="./img/wrench-fallback.png"
                effect="lines"
              ></MaskEffectImage>
            </div>
          </div>
        </div>
        <div class="col col--4-12 col-content">
          <div class="content-text col__layer">
            <p class="mono-big">&lt;Diensten/&gt;</p>
            <h2 class="h2">Mobiele apps.</h2>
            <p
              class="text"
            >Het ontwerpen, ontwikkelen en/of implementeren van nieuwe apps, maar ook het doorontwikkelen van bestaande apps; Kroot kan ermee overweg. Het kan hierbij gaan om bijvoorbeeld spelletjes, maar ook om meer functionele toepassingen zoals navigatie, reisinformatie, nieuws en internetbankieren.</p>
            <BaseButton
              ghost
              router-link
              :to="{name: 'Contact'}"
              aria-label="Neem contact met ons op"
            >Zoek niet verder</BaseButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BaseButton, MaskEffectImage, BaseImage } from '@/components/global';

export default {
  name: 'MobileAppsRow',
  props: {},
  components: {
    BaseButton,
    MaskEffectImage,
    BaseImage,
  },
};
</script>

<style lang="scss" scoped>
.iphone-animated {
  position: relative;
  right: -100px;
  justify-content: center;
  align-items: center;
  transform: translateX(-140px) rotate(30deg);

  .iphone-image {
    width: 1093px * 0.9;
    height: auto;
  }

  .wrench-image {
    position: absolute;
    top: 102px * 0.9;
    left: 398px * 0.9;
    width: 52.5%;
  }
}

_:-ms-fullscreen,
:root .iphone-animated {
  left: -80px;
}

@include lg {
  .iphone-animated {
    left: 150px;
    .iphone-image {
      width: 1093px * 0.65;
    }

    .wrench-image {
      top: 102px * 0.65;
      left: 398px * 0.65;
      width: 52.5%;
    }
  }

  _:-ms-fullscreen,
  :root .iphone-animated {
    left: 0px;
  }
}
</style>
