<template>
  <section
    id="backend"
    class="service-row page-row page-row--default page-row--small-margin page-row--bg--grey"
  >
    <div class="container container--inherit-height">
      <div class="col col--12-12 row-inner-container">
        <div class="col col--4-12 col-content">
          <div class="content-text col__layer">
            <p class="mono-big">&lt;Diensten/&gt;</p>
            <h2 class="h2">Backend ontwikkeling.</h2>
            <p
              class="text"
            >Gewapend met een specialisme in technieken als PHP, Node.js en Typescript beheren en implementeren wij back-end toepassingen voor digitale platformen. Heb jij een goed front-end idee, maar toevallig nooit leren coderen? We got your back(end).</p>
            <BaseButton
              ghost
              router-link
              :to="{name: 'Contact'}"
              aria-label="Neem contact met ons op"
            >Zoek niet verder</BaseButton>
          </div>
        </div>
        <div class="col col--7-12">
          <div class="content-image col__layer">
            <div class="ipad-animated">
              <BaseImage :max="2" src="ipad.png" class="ipad-image" alt="iPad"></BaseImage>
              <MaskEffectImage
                class="axe-image"
                image-below-src="./img/chisel-below.png"
                image-above-src="./img/chisel-above.png"
                image-fallback-src="./img/chisel-fallback.png"
                effect="clockwork"
              ></MaskEffectImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BaseButton, MaskEffectImage, BaseImage,
} from '@/components/global';

export default {
  name: 'BackendRow',
  props: {},
  components: {
    BaseButton,
    MaskEffectImage,
    BaseImage,
  },
};
</script>

<style lang="scss" scoped>
.service-row {
  .content-image {
    left: -100px;
    .ipad-animated {
      position: relative;
      justify-content: center;
      align-items: center;
      transform: translateX(140px) rotate(-30deg);

      .ipad-image {
        width: 683px;
        height: auto;
      }

      .axe-image {
        position: absolute;
        top: 112px;
        left: 137px;
      }

      @include lg {
        right: 60px;

        .ipad-image {
          width: 683px * 0.7;
        }

        .axe-image {
          top: 112px * 0.7;
          left: 137px * 0.7;
          width: 77%;
        }
      }
    }
  }
}

//csshack for IE11 and microsoft edge
_:-ms-lang(x),
.axe-image {
  width: 77%;
}
</style>
