<template>
  <header class="page-row hero-row">
    <div class="container">
      <div class="hero-container col col--12-12">
        <h1 class="h2 text-top">Jij bedenkt het idee</h1>
        <MaskEffectImage
          image-above-src="./img/Services/Main_afbeelding.png"
          image-below-src="./img/Services/Main_afbeelding-2.png"
          image-fallback-src="./img/Services/Main_afbeelding_fallback.png"
          effect="hover"
          class="hero-image"
        ></MaskEffectImage>
        <h1 class="h2 text-bottom">Wij creëren de code</h1>
      </div>
    </div>
  </header>
</template>

<script>
import { MaskEffectImage } from '@/components/global/';

export default {
  name: 'ServiceHeroRow',
  components: {
    MaskEffectImage,
  },
  data() {
    return {
      mouseOver: false,
    };
  },
};
</script>

<style scoped lang="scss">

.container {
  margin-top:160px;
}


.hero-container {
  width: 100%;
  height: 100%;
  position: relative;
  .text-top {
    position: absolute;
    top: 0px;
    white-space: nowrap;
    left: 180px;
    z-index: 30;
  }

  .text-bottom {
    position: absolute;
    bottom: 30px;
    right: 80px;
    color: white;
    z-index: 30;
  }

  .hero-image {
    width:100%;
    height:auto;
  }
}

@include xlg {
  .hero-container {
    .text-bottom {
      right: 20px;
    }
    .text-top {
      left: 150px;
    }
  }
}

@include lg {
  .hero-container {
    .text-bottom {
      right: 10px;
    }
  }
}

@include md {
  .hero-container {
    .text-bottom {
      right: 10px;
      bottom: 5px;
    }
    .text-top {
      left: 120px;
    }
  }
}

@include sm {
  .hero-container {
    margin-top: 40px;
    .hero-image {
      display: none;
    }
    .text-top,
    .text-bottom {
      position: relative;
      text-align: center;
    }
    .text-top {
      white-space: unset;
      left: 0px;
      margin-bottom: 20px;
    }
    .text-bottom {
      color: black;
      left: 0px;
    }
  }
}
</style>
