<template>
  <section class="service-row page-row page-row--default page-row--small-margin page-row--bg--white">
    <div class="container container--inherit-height">
      <div class="col col--12-12 row-inner-container">
        <div class="col col--4-12 col-content">
          <div class="content-text col__layer">
            <p class="mono-big">&lt;Diensten/&gt;</p>
            <h2 class="h2">Iets anders nodig?</h2>
            <p
              class="text"
            >Bij Kroot geloven wij dat technische innovatie en optimale gebruikerservaring samen komen tot intuïtieve mobiele oplossingen. Jij hebt een complex probleem en een concept, wij hebben de vaardigheden om dat een realiteit te maken.</p>
            <BaseButton
              ghost
              ghost-red
              router-link
              :to="{name: 'Contact'}"
              aria-label="Neem contact met ons op"
            >Zoek niet verder</BaseButton>
          </div>
        </div>
        <div class="col col--7-12">
          <div class="content-image col__layer">
            <BaseImage :max="2" src="gloves.png" class="gloves-image" alt="Gloves"></BaseImage>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BaseButton, BaseImage } from '@/components/global';

export default {
  name: 'MiscRow',
  props: {},
  components: {
    BaseButton,
    BaseImage,
  },
};
</script>

<style lang="scss" scoped>
.gloves-image {
  width: 800px;
  height: auto;
}

_:-ms-fullscreen, :root .gloves-image {
  width: 100%;
}
</style>
