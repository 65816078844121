<template>
  <section id="webapps" class="service-row page-row page-row--default page-row--bg--black page-row--small-margin">
    <div class="container container--inherit-height">
      <div class="col col--12-12 row-inner-container">
        <div class="col col--7-12">
          <div class="content-image col__layer">
            <div class="imac-animated">
              <BaseImage :max="2" src="imac.png" class="imac-image" alt="iMac"></BaseImage>
              <MaskEffectImage
                class="axe-image"
                image-below-src="./img/axe-below.png"
                image-above-src="./img/axe-above.png"
                image-fallback-src="./img/axe-fallback.png"
                effect="triangles"
              ></MaskEffectImage>
            </div>
          </div>
        </div>
        <div class="col col--4-12 col-content">
          <div class="content-text col__layer">
            <p class="mono-big">&lt;Diensten/&#x3E;</p>
            <h2 class="h2">Webapplicaties.</h2>
            <p
              class="text"
            >Heb jij een webapplicatie nodig voor bijvoorbeeld Customer Relationship Management, e-mail, administratiesoftware, ordersystemen, online agendabeheer of intranet- en extranetomgevingen? Standaard of op maat gemaakt? Kroot regelt het.</p>
            <BaseButton
              ghost
              ghost-red
              router-link
              :to="{name: 'Contact'}"
              aria-label="Neem contact met ons op"
            >Zoek niet verder</BaseButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BaseButton, MaskEffectImage, BaseImage,
} from '@/components/global';

export default {
  name: 'WebAppsRow',
  props: {},
  components: {
    BaseButton,
    MaskEffectImage,
    BaseImage,
  },
};
</script>

<style lang="scss" scoped>
.imac-animated {
  position: relative;
  justify-content: center;
  align-items: center;
  .imac-image {
    width: 1115px * 0.9;
    height: auto;
  }

  .axe-image {
    position: absolute;
    top: 143px * 0.9;
    left: 212px * 0.9;
    width: 79%;
  }
}

//csshack for IE11
_:-ms-fullscreen,
:root .imac-animated {
  left: -250px;
}

@include lg {
  //csshack for IE11
  _:-ms-fullscreen,
  :root .imac-animated {
    left: -50px;
  }

  .imac-animated {
    .imac-image {
      width: 1115px * 0.5;
    }

    .axe-image {
      top: 143px * 0.5;
      left: 212px * 0.5;
      width: 79%;
    }
  }
}
</style>
