<template>
  <div>
    <ServiceHeroRow />
    <main>
      <WordpressRow />
      <WebAppsRow />
      <BackendRow />
      <MobileAppsRow />
      <MiscRow />
      <CallToActionRow />
    </main>
  </div>
</template>

<script>
import {
  ServiceHeroRow,
  WordpressRow,
  BackendRow,
  MiscRow,
  MobileAppsRow,
  WebAppsRow,
} from '@/components/rows/services';

import CallToActionRow from '@/components/rows/CallToActionRow.vue';

export default {
  name: 'Services',
  components: {
    ServiceHeroRow,
    WordpressRow,
    BackendRow,
    MiscRow,
    MobileAppsRow,
    WebAppsRow,
    CallToActionRow,
  },
  head: {
    title: {
      inner: 'Diensten',
    },
    meta: [
      { name: 'description', content: 'Kroot. Diensten', id: 'desc' },
      { property: 'og:description', content: 'Kroot. Specialisten in softwareontwikkeling' },
      { property: 'og:title', content: 'Kroot. Digitaal Vakmanschap' },
      { property: 'og:url', content: 'https://kroot.dev/diensten' },
      { name: 'twitter:card', content: 'Kroot. Specialisten in softwareontwikkeling' },
    ],
    link: [{ rel: 'canonical', href: 'https://kroot.dev/diensten' }],
  },
};
</script>
<style scoped lang="scss"></style>
