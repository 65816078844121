<template>
  <section id="wordpress" class="service-row page-row page-row--default page-row--small-margin page-row--bg--blue">
    <div class="container container--inherit-height">
      <div class="col col--12-12 row-inner-container">
        <div class="col col--4-12 col-content">
          <div class="content-text col__layer">
            <p class="mono-big">&lt;Diensten/&gt;</p>
            <h2 class="h2">
              Websites op maat.
            </h2>
            <p
              class="text"
            >Ons bread-and-butter. Websites maken voor uw bedrijf is wat wij doen. Of dat nu via een Wordpress template gebeurt of volledig custom-made. Of een combinatie van de twee! Wij kijken mee naar wat het beste is voor jouw bedrijf, en gaan daarmee aan de slag.</p>
            <BaseButton
              ghost
              ghost-red
              router-link
              :to="{name: 'Contact'}"
              aria-label="Neem contact met ons op"
            >Zoek niet verder</BaseButton>
          </div>
        </div>
        <div class="col col--7-12 col-image">
          <div class="content-image col__layer">
            <div class="macbook-animated">
              <BaseImage :max="2" src="macbook.png" class="macbook-image" alt="MacBook"></BaseImage>
              <MaskEffectImage
                class="hook-image"
                image-below-src="./img/hook-below.png"
                image-above-src="./img/hook-above.png"
                image-fallback-src="./img/hook-fallback.png"
                effect="circles"
              ></MaskEffectImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BaseButton, MaskEffectImage, BaseImage } from '@/components/global';

export default {
  name: 'WordpressRow',
  props: {},
  components: {
    BaseButton,
    MaskEffectImage,
    BaseImage,
  },
};
</script>

<style lang="scss" scoped>
.macbook-animated {
  //TODO: Resize animation TOO!
  position: relative;
  justify-content: center;
  align-items: center;
  .macbook-image {
    width: 1268px;
    height: auto;
  }

  .hook-image {
    position: absolute;
    top: 190px;
    left: 331px;
  }
}

//csshack for IE11
_:-ms-fullscreen,
:root .macbook-animated {
  right: 300px;
}

//csshack for IE11 and microsoft edge
_:-ms-lang(x), .hook-image {
  width:70%;
}

@include lg {
  .macbook-animated {
    right: 40px;
    .macbook-image {
      width: 1268px * 0.6;
    }

    .hook-image {
      top: 190px * 0.6;
      left: 331px * 0.6;
      width: 70%;
    }
  }

  //csshack for IE11
  _:-ms-fullscreen,
  :root .macbook-animated {
    right: 250px;
  }
}
</style>
